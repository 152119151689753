@import '../../variables';

.navigation {
  z-index: 20;
  background: #fff;
  text-transform: uppercase;
  position: relative;
  font-family: $fontDefault;
  border-bottom: 1px solid $borderColor;

  .nav-wrapper {
    display: flex;
    align-items: center;
    font-size: $fontSizeSmall;
    padding: 0 18px;
    @media (max-width: #{$breakpoint-sm}) {
      padding: 0;
    }
    .nav-item-list {
      flex-grow: 1;
      padding: 0 10px 0 50px;

      nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
          color: $darkBackground;
          font-size: $fontSizeSmall;
          font-family: $fontCaps;
          margin: 0 50px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .login {
    svg {
      margin-right: 6px;
    }
  }

  .logo {
    padding-left: 0;

    @include md {
      padding-left: 10px;
    }

    a {
      color: $textColor;
      display: flex;
      justify-content: center;
      font-family: $fontCaps;
      align-items: center;
      line-height: 1.1;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin: 15px 0;

      @include md {
        margin: 15px 0;
      }

      span {
        display: none;

        @include md {
          display: inline;
        }
      }

      img {
        width: 112px;
        margin-left: 0;
      }
    }
  }

  .profile {
    &:hover {
      opacity: 0.9;
    }

    button {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-right: 1px;
      height: 40px;
      padding: 8px 10px;

      @include xs {
        padding: 8px 20px;
      }
    }
  }

  .logout {
    &:hover {
      opacity: 0.9;
    }

    button {
      height: 40px;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-width: 0;
      padding: 8px 10px;

      @include xs {
        padding: 8px 20px;
      }
    }
  }

  @include lg {
    .burger {
      display: none;
    }
  }

  @media (max-width: #{$breakpoint-lg}) {
    & > .logo {
      align-self: flex-start;
      flex: 1;
    }
  }
}

.MuiDrawer-paper.MuiPaper-root {
  .MuiList-root {
    .MuiListItem-root {
      a {
        font-family: $fontCaps;
        font-size: $fontSizeDefault;
        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }
}
