@import '../../variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  &.Headline {
    color: $textColor;
    flex-grow: 1;
    font-family: $fontDefault;
    margin-top: 0;
    font-weight: 100;

    &.centered {
      text-align: center;
    }

    span {
      font-family: $fontDefault;
    }

    p {
      margin: 0;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: $textColor;
      font-family: $fontDefault;
    }

    &.primary {
      color: $primaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $primaryColor;
      }
    }

    &.secondary {
      color: $secondaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $secondaryColor;
      }
    }

    &.white {
      color: #ffffff;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: #ffffff;
      }
    }

    &.thin {
      font-weight: 100;
    }
  }
}

h1.Headline {
  font-weight: 600;
  font-size: calc(#{$fontSizeHeadline1} * 0.75);

  @include sm {
    font-size: $fontSizeHeadline1;
  }

  @include md {
    font-size: calc(#{$fontSizeHeadline1} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline2;
    font-weight: 100;
  }
}

h2.Headline {
  font-size: calc(#{$fontSizeHeadline2} * 0.75);

  @include sm {
    font-size: $fontSizeHeadline2;
  }

  @include md {
    font-size: calc(#{$fontSizeHeadline2} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline3;
  }
}

h3.Headline {
  font-size: $fontSizeHeadline3;

  @include md {
    font-size: calc(#{$fontSizeHeadline3} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline4;
  }
}

h4.Headline {
  font-size: $fontSizeHeadline4;
}
