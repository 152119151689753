@import '../../variables';

.Transactions {
  .MuiPaper-root {
    .MuiTable-root.MuiTable-root td {
      height: auto !important;
    }
  }

  p {
    color: $subNavTextColor;
  }

  span.filter-label {
    font-size: 14px;
  }

  .tableFilter {
    display: flex;
    flex-direction: column;

    .MuiInput-root.rounded-select .MuiSelect-select {
      font-family: $fontDefault, sans-serif;
    }

    & > div {
      margin: 0 0 16px;
      width: 100%;

      .MuiInput-root.rounded-select {
        width: 100%;
        margin: 0;
      }
    }

    @include sm {
      flex-direction: row;

      & > div {
        margin: 0 8px 30px;
      }

      & > div:first-child {
        margin-left: 0;
      }

      & > div:last-child {
        margin-right: 0;
      }
    }
  }
}
