$breakpoint-xs: '0px';
$breakpoint-sm: '600px';
$breakpoint-md: '1140px';
$breakpoint-lg: '1280px';
$breakpoint-xl: '1920px';

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin xsMax {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin smMax {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin mdMax {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #e89e40;
$secondaryColor: #688b9e;
$textColor: #000;
$lightTextColor: #737373;
$highlightColor: #009fd6;
$secondaryHighlightColor: #6cb7a9;
$borderColor: #e7e7e7;
$subNavTextColor: #4c4c4c;
$subNavBackground: #eae7e1;
$ultraLightBackground: #f4f3f0;
$lightBackground: #f1f6f9;
$mediumBackground: #35647d;
$darkBackground: #033d5d;
$shadowColor: #0000001a;
$highlightTableRowColor: #fafafa;

$errorColorLight: #f4b0a9;
$errorColorLighter: #fdf1f0;
$errorColorDarker: #c15b52;
$warningColorLight: #faec91;
$warningColorLighter: #fdf8da;
$warningColorDarker: #c4af2b;
$successColorLighter: #b9ddb4;
$successColorLight: #91cb8a;
$successColorDarker: #77a771;

$starColor: $primaryColor;

$fontDefault: 'mlp-ot', sans-serif;
$fontCaps: 'mlp-ot-caps', sans-serif;

$fontSizeDefault: 18px;
$fontSizeSmall: 16px;
$fontSizeExtraSmall: 14px;

$fontSizeHeadline1: 36px; // 40px
$fontSizeHeadline2: 30px; // 25px
$fontSizeHeadline3: 24px; // 16px
$fontSizeHeadline4: 20px; // 12px

$fontSizeDashboardCardHeadline: 20px;

$boxPadding: 30px 30px;
$TileBoxShadow: 0px 2px 15px $shadowColor;
