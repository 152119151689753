@import '../../variables';

.submenu {
  background: $subNavBackground;
  position: relative;
  z-index: 2;

  .submenuWrapper {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 1200px;
    width: 100%;
    flex-direction: row;
    margin: 0 auto;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    padding-right: 172px;

    @media (max-width: #{$breakpoint-md}) {
      justify-content: flex-start;
      padding-right: 15px;
      padding-left: 15px;
    }

    > .subNavigation {
      > ul {
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
        flex-grow: 1;
        flex-direction: row;
        width: auto;
        list-style-type: none;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 10px;

        @media (max-width: #{$breakpoint-md}) {
          padding: 0 20px;
        }

        li {
          margin: 0 5px;

          &:last-child {
            margin: 0 0 0 5px;
          }

          &:first-child {
            a {
              margin-left: 0;
            }
          }

          &:last-child {
            a {
              margin-right: 10px;
            }
          }

          a {
            display: inline-block;
            color: $subNavTextColor;
            padding: 10px 0;
            text-transform: uppercase;
            margin-left: 30px;
            position: relative;
            white-space: nowrap;
            font-family: 'mlp-ot-caps';
            font-size: 16px;

            &.active {
              font-weight: bold;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
