@import '../../variables';

.InnerLayout.ProductDetail {
  padding-top: 0;

  .shortLinks {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;

      span {
        margin-top: 15px;
        font-family: 'mlp-ot-caps', sans-serif;
        text-transform: uppercase;
        color: $mediumBackground;
        vertical-align: middle;
        display: inline-block;
        line-height: 1;

        &:first-child {
          width: 20px;
          text-align: center;
        }
      }
    }
  }

  .shopWrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    .shopLogo {
      max-height: 80px;
      margin-right: 40px;
    }
  }
}
