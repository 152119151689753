@import '../../variables';

.ImageSlider {
  background: transparent;

  @media (max-width: #{$breakpoint-md}) {
    background: #fff;
  }

  .slide {
    width: 100% !important;
    background-position: center center !important;
    background-size: cover !important;
    position: relative;

    .responsiveImage {
      width: 100%;
    }
  }

  .swiper-pagination-bullet-active {
    background: $secondaryColor !important;
  }

  .swiper-pagination-bullet {
    background-color: #eee;
    opacity: 0.9;
    border: 1px solid #eee;
  }
}
