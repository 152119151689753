@import '../../variables';

.Box {
  padding: $boxPadding;
  background-color: #ffffff;

  &.blue {
    background-color: $lightBackground;
  }

  &.transparent {
    background-color: transparent;
  }

  &.noHorizontalPaddingOnMobile {
    @include xsMax {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.noVerticalPaddingOnMobile {
    @include xsMax {
      padding-top: 0;
      padding-bottom: 0 !important;
      .InnerLayout {
        padding: 0;
      }
    }
  }

  &.login-box {
    padding: 60px 120px 60px 120px;

    @include smMax {
      padding: 30px 60px;
    }

    @include xsMax {
      padding: 30px;
    }
  }
}
