@import '../../variables';

.CashbackList {
  @include xsMax {
    .InnerLayout .Box {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.filter {
  .Box {
    border: 1px solid #e4e4e4;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    border: 1px solid #ccc;
    padding: 8px 15px !important;
    margin: 8px;

    .MuiButton-label {
      font-size: 1rem;
      color: #707070;
    }
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    padding: 8px 15px !important;
    border: 1px solid $primaryColor;
    margin: 8px;
  }
}

.MuiInput-root.rounded-select {
  border-color: #ccc !important;
  width: 100%;
  margin: 0;
  border-radius: 4px !important;

  .MuiSelect-select {
    color: #707070;
    font-family: $fontDefault;
    font-weight: 300;
    padding: 15px 15px 9px 15px !important;
    font-size: $fontSizeSmall;
    color: $textColor;
  }
}

.resetFilterWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & > [type='button'] {
    margin-left: 10px;

    & > span.MuiButton-label {
      font-size: 15px !important;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @include xsMax {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.combined {
  .HeroBox {
    .modeSwitch {
      button {
        margin-right: 5px;
        padding: 12px 15px;
        background: none;
        box-shadow: none;
        font-family: $fontDefault;
        font-weight: 300;
        font-size: $fontSizeExtraSmall;
        border: 1px solid #ccc;
        color: #707070;
        margin-top: 5px;

        @include md {
          margin-top: 0;
        }

        &.active {
          background: $primaryColor;
          border-color: $primaryColor;
          color: #fff;
        }
      }
    }
  }

  .shops-list {
    margin: 0 0 0 -7px !important;
  }

  .products-list {
    border-top: 3px solid #e7e7e7;
    padding-top: 30px;
    margin-top: 40px !important;
  }
}

.shops {
  .shops-list {
    margin-top: 0 !important;
  }
}

.products {
  .shops-list {
    display: none;
  }
  .products-list {
    margin-top: 0 !important;
  }
}

.filterHolder {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }
}

table {
  box-shadow: none !important;
}

.goToShopPanel {
  width: 100%;
}

div.shopTypeSwitchWrapper {
  // font-weight: normal;
  display: flex;
  flex-direction: row;
  padding: 30px 0 30px 0;
  @include smMax {
    padding: 0;
  }

  .shopTypeSwitch {
    padding: 0 4px 5px;
    color: $secondaryColor;
    font-size: 28px;
    opacity: 1;

    &.active {
      border-bottom: 2px solid $darkBackground;
      color: $darkBackground;
      opacity: 1;
    }

    &:first-child {
      padding-left: 0;
      margin-right: 30px;
    }

    &:last-child {
      padding-right: 0;
    }

    @include smMax {
      font-size: $fontSizeHeadline4;

      &.active {
        border-bottom-width: 1px;
      }
      &:first-child {
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
  }
}

.priceRangeFilter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  h4.Headline {
    flex-grow: 0;
  }

  .MuiSlider-root {
    max-width: 96%;
  }

  .MuiSlider-rail {
    color: #eeeeee;
    opacity: 1;
    height: 10px;
    border-radius: 4px;
  }

  .MuiSlider-track {
    color: $darkBackground;
    height: 10px;
  }

  .MuiSlider-thumb {
    color: $secondaryHighlightColor;
    width: 32px;
    height: 32px;
    margin-top: -11px;
    border: 3px solid #fff;
    box-shadow: 0px 3px 6px #00000029;
    transition: none;

    &:hover {
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .MuiSlider-valueLabel {
    display: none;
  }
}

@include xsMax {
  .priceRangeInputs {
    flex-direction: column;

    .priceRangeInput {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;

      span {
        width: 25px;
      }
    }
  }
}

div.priceRangeInputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 0 10px;

  .price-range {
    border: 1px solid $borderColor;
    border-radius: 4px;
    margin: 0 20px;
    padding: 5px 10px;

    input {
      text-align: center;
      font-size: $fontSizeSmall;
      padding-left: 15px;
      padding-right: 5px;
    }
  }

  .currency {
    font-size: $fontSizeSmall;
  }
}

button.filterButton {
  width: 100%;
  margin-right: 0;
  color: $mediumBackground;

  & .MuiButton-label {
    font-weight: bold !important;
  }

  @include xs {
    width: calc(50% - 4px);
    margin-right: 0;

    &:nth-child(odd) {
      margin-right: 8px;
    }
  }

  @include sm {
    width: auto;
    margin-right: 8px !important;
  }

  &.MuiButton-root .MuiButton-label {
    font-size: $fontSizeSmall !important;

    @include sm {
      font-size: $fontSizeSmall;
    }
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.filterButton.MuiButton-containedPrimary {
  color: white;
  background-color: $mediumBackground;
}
