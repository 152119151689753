@import '../../variables';

.IntroSection {
  margin-top: 30px;
  @include xsMax {
    margin-top: 0;
  }
  p {
    margin-top: 0;
  }

  & .contact-information {
    text-align: right;
    margin-left: 30px;

    .phone {
      text-align: right;
      vertical-align: center;
      color: $mediumBackground;
      font-size: 42px;
      margin-bottom: 20px;

      @include xsMax {
        font-size: 30px;
      }

      a {
        color: $mediumBackground;
      }

      svg {
        width: 50px;
      }
    }

    .service-hours {
      float: right;

      @include xsMax {
        font-size: $fontSizeExtraSmall;
      }
    }

    .service-hours > div {
      display: flex;
      justify-content: space-between;

      & > div:last-child {
        margin-left: 20px;
      }
    }
  }
}
