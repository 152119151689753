@import '../../variables';

.Dashboard {
  h1 {
    color: $darkBackground;
  }
  .dashboardIntro {
    width: 70%;
    @include xsMax {
      width: 100%;
    }
  }
}
