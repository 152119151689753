@import '../../variables';

.createComplaint {
  .MuiGrid-root {
    .MuiGrid-item {
      margin: 5px 0;

      textarea {
        border: 1px solid $borderColor;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        min-height: 150px;
        width: 100%;
        resize: vertical;
        &:focus {
          outline: 0;
          border-radius: 5px;
          border: 2px solid $secondaryColor;
          box-shadow: 0 0 5px $secondaryColor;
        }
      }

      .MuiFormControl-root {
        .MuiFormLabel-root.Mui-focused {
          color: $secondaryColor;
        }

        .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
          background-color: #fff;
          border: 1px solid $borderColor;

          &:after {
            border-bottom: 2px solid $secondaryColor;
          }

          &.Mui-focused {
            box-shadow: 0 0 5px $secondaryColor;
            border: 1px solid $secondaryColor;
            &:after {
              border-bottom: 2px solid $secondaryColor;
            }
          }

          .MuiSelect-root.MuiSelect-select {
            input {
              background-color: #fff;
            }
          }
        }
      }

      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border-color: $borderColor;
        }
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $secondaryColor;
            box-shadow: 0 0 5px $secondaryColor;
          }
        }
      }
    }
  }

  .validation-explanation {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
}
