@import '../../variables';

.layout {
  position: relative;

  .content-wrapper {
    background: #ffffff;
  }

  .sidebarDrawer {
    z-index: 1000;
    position: fixed;
    top: 50%;
    right: -310px;
    transition: right 0.5s;
    width: 310px;

    &.active {
      right: 0;
    }

    .sidebarDrawerIconsWrapper {
      left: -42px;
      position: absolute;
    }

    .sidebarDrawerIcons {
      background: $primaryColor;
      color: #ffffff;
      padding: 10px;
      cursor: pointer;

      svg {
        display: block;
        font-size: 22px;

        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }

    .sidebarDrawerContent {
      background: #fff;
      border: 1px solid #e7e7e7;
      padding: 15px 30px;
      margin-top: -100px;

      p:last-of-type {
        margin-top: 0;
      }

      h3 {
        color: $primaryColor;
        margin-bottom: 0;
      }

      svg {
        color: $primaryColor;
        font-size: 26px;
      }

      .icon-box {
        padding: 15px 0;
        border-bottom: 1px solid #f0f0f0;
      }

      .icon-box-icon {
        padding: 16px 0;
      }

      .icon-content-small {
        font-size: $fontSizeExtraSmall;
        color: $textColor;
        width: 100%;
        display: inline-block;
      }

      .icon-content-big {
        font-size: $fontSizeDefault;
        font-weight: 300;
        width: 100%;
        display: inline-block;

        a {
          color: $textColor;
        }
      }
    }
  }
}

.MuiDrawer-paper.MuiPaper-root {
  background: rgba($secondaryColor, 0.8);
  border-left: 1px solid rgba($secondaryColor, 0.8);

  .MuiDivider-root {
    background: #ffffff44;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
}
