@import '../../variables';

.above-table {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  .search {
    display: flex;
    align-items: center;

    .table-search {
      margin-left: 20px;
    }
  }

  .itemsPerPage {
    margin-top: 20px;

    .MuiInputBase-root {
      margin-left: 20px;
    }
  }
}

.responsiveTable {
  width: 100%;
  border-spacing: 0;
  font-size: 16px;

  thead,
  tfoot {
    background-color: $lightBackground;
    display: none;

    @include sm {
      display: table-header-group;
    }
  }

  .tableHead {
    cursor: pointer;
    font-family: $fontDefault;
    font-weight: 100;
  }

  .tableFooter {
    font-size: 16px;
    padding: 12px 16px 8px;
    color: $secondaryColor;
    vertical-align: middle;
    border-spacing: 0;
    font-family: $fontDefault;
    font-weight: 100;
  }

  &.sumRow {
    .tableRow:last-of-type {
      font-weight: bold;
      display: none;

      @include sm {
        display: table-row;
      }
    }
  }

  .tableRow.hiddenRow {
    background: $lightBackground;
    padding: 5px;

    .entityValue {
      text-align: right;
    }

    .entityLabel {
      font-weight: bold;
    }

    .entityLabel,
    .entityValue {
      padding: 5px 10px;
    }
  }

  td {
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    font-family: $fontDefault, sans-serif;
  }

  tr {
    &.highlight {
      background: $highlightTableRowColor;
    }

    & + tr {
      td {
        border-top: 0 none;
      }
    }
  }

  td {
    padding: 12px 16px 10px;
    border-spacing: 0;
    color: $subNavTextColor;
  }

  th {
    font-size: 16px;
    padding: 12px 16px 8px;
    color: $secondaryColor;
    vertical-align: middle;
    border-spacing: 0;
  }

  &.benefits {
    border-top: 0 none;

    .tableHead {
      cursor: pointer;
      background-color: transparent;
      color: $primaryColor;
    }

    .tableRow.hiddenRow {
      background: transparent;
      padding: 5px;
    }

    td {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      font-family: $fontDefault, sans-serif;
    }

    th {
      font-size: 1.2em;
    }

    th,
    td {
      padding: 8px 24px;
      border-spacing: 0;
    }

    th {
      padding: 8px 24px 16px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;

  .itemsPerPage {
    margin-top: 20px;
    .MuiInputBase-root {
      margin-left: 10px;
    }
  }

  .paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Pagination {
      padding-top: 20px;
    }

    .previous,
    .next {
      padding: 5px 10px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
