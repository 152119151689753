@import '../../variables';

.ShopDetail {
  padding-top: 0;

  @include smMax {
    & > div > div:nth-child(1),
    & > div > div:nth-child(2) {
      padding: 0 !important;
    }
  }

  &-vouchers {
    & > div:first-child {
      // margin-top: 0;
      padding-top: 0;

      & > button {
        top: -0.5em;
      }
    }

    & > div:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  strong {
    color: $primaryColor;
  }

  &-voucher {
    border-bottom: 1px solid #f0f0f0;
    padding-top: 1em;
    padding-bottom: 2em;
    margin-top: 30px;

    .voucherIntro {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .voucherDescription {
      max-width: 100%;
      margin-bottom: 20px;

      @include md {
        max-width: 70%;
      }
    }

    & > button,
    & > a {
      float: right;
      position: relative;
      top: 1em;
      color: #fff;
      border-color: $primaryColor;
      border-style: solid;

      &.MuiButton-containedPrimary {
        margin-top: -15px;

        span.MuiButton-label {
          color: #fff !important;
        }
      }

      span {
        color: $primaryColor !important;
      }
    }

    p {
      max-width: 700px;
    }

    h3 {
      color: $primaryColor;
      margin-bottom: 1.5em;
    }

    &-info {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      font-weight: 300;
    }
  }

  .color-secondary {
    color: $secondaryColor;
  }

  .shortLinks {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;

      svg {
        margin-top: -2px;
      }

      button,
      a {
        border: 0 none;
        background: transparent;
        padding: 0;

        &:active,
        &:focus {
          outline: none;
        }

        span {
          font-size: 18px;
          margin-top: 15px;
          font-family: 'mlp-ot-caps', sans-serif;
          text-transform: uppercase;
          color: $mediumBackground;
          vertical-align: middle;
          display: inline-block;
          line-height: 1;

          &:first-child {
            width: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  .favouriteStar {
    button {
      border: 0 none;
      background: transparent;
      padding: 0;

      &:active,
      &:focus {
        outline: none;
      }

      span {
        font-size: 18px;
      }
    }
  }
}
