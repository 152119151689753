@import '../../variables';

.InputWithButton {
  width: 100%;

  &.MuiPaper-root {
    display: flex;
    justify-content: space-between;
    border: 1px solid #cccccc;
    background: #fff;

    .MuiInputBase-root {
      padding: 0 15px;
      height: 44px;
      line-height: 44px;
      flex: 1;
      color: #707070;
      font-weight: 300;
      font-size: $fontSizeSmall;

      & input::placeholder {
        opacity: 1;
        color: $textColor;
        font-size: $fontSizeSmall;
        font-family: $fontDefault;
      }
    }
  }

  .icon-button-container {
    margin: auto 10px;
    font-size: 12px;
  }
}
