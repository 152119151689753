@import '../../variables';

.Settings {
  .headlineWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 30px;
    justify-content: space-between;
    @media (max-width: #{$breakpoint-sm}) {
      display: block;
      h2 {
        width: 100%;
      }
    }
  }
}
