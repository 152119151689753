@import '../../variables';

.Backlink {
  position: relative;
  font-size: 1rem;
  padding-bottom: 20px;
  padding-top: 20px;

  a {
    text-decoration: none;
    color: $darkBackground;
    cursor: pointer;
    text-transform: uppercase;

    &.black {
      color: $textColor;
    }
  }

  svg {
    margin-right: 6px;
  }
}
