@import '../variables';

.Login {
  .loginField {
    label {
      font-weight: 300;
      color: $secondaryColor;
      font-size: 1.25em;
      font-family: $fontDefault;
    }

    input {
      font-size: 1.25em;
      width: 100%;
    }
  }

  svg {
    color: $secondaryColor;
  }

  .loginContent {
    @include md {
      width: 80%;
    }

    & a.color-primary {
      color: $primaryColor;
    }
  }

  .loginButtons {
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
      justify-content: flex-end;
    }

    button {
      width: 100%;

      @include sm {
        width: auto;
      }

      &.submitLogin {
        margin-top: 10px;
        margin-left: 0;
        order: 1;

        @include sm {
          margin-left: 10px;
          order: 2;
        }
      }
    }

    .buttonRegister {
      margin-top: 10px;
      order: 2;

      @include sm {
        order: 1;
      }
    }
  }
  .login-icon {
    @include xsMax {
      display: none;
    }
  }
}

.resendButton {
  color: rgb(232, 158, 64);
  cursor: pointer;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  border: 0 none;
  background: transparent;

  &:active,
  &:focus {
    outline: none;
  }
}
