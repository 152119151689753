@import "../../variables";

.InnerLayout {
  padding-top: 20px;
  padding-bottom: 20px;

  &.colorful {
    background-color: $ultraLightBackground;
  }
}
