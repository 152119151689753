@import '../../variables';

.Travel {
  .twoColorLink,
  .twoColorLink:visited,
  .twoColorLink:focus,
  .twoColorLink:active,
  .twoColorLink:hover {
    color: $textColor;
  }

  @include xsMax {
    .Box {
      padding-top: 10px !important;
    }

    .noVerticalPaddingOnMobile h3 {
      padding-top: 20px;
    }
  }

  .loginRequired {
    width: 80%;
    padding: 40px 30px;
    background: $secondaryColor;
    color: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include sm {
      width: 50%;
      padding: 2em;
    }
  }

  p.intro-text {
    margin-top: 0; // overwrite browser styles
  }
}
